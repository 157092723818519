import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import "../style/___main.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Layout from "../components/Layout";
import { useQuerySubscription } from "react-datocms";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Gallerij() {
  // let photoArray = data.allDatoCmsGallery.nodes;
  const [ photoArray, setPhotoArray ] = useState([]);
  
  const { status, error, data } = useQuerySubscription({
    enabled: true,
    query: query,
    variables: { first: 20 },
    token: '0907f4cfec4809b1b584728b404e77',
  });
  const statusMessage = {
    connecting: 'Connecting to DatoCMS...',
    connected: 'Connected to DatoCMS, receiving live updates!',
    closed: 'Connection closed',
  };

  useEffect(() => {
    if (data) {
      setPhotoArray(data.allGalleries)
    }
    console.log(data)
  }, [data])

  return (
    <div className="gallerij">
      <Layout>
        <ResponsiveMasonry
          className="ResponsiveMasonry"
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <h1>Galerij</h1>
          <Masonry>
            {photoArray.map((item) => {
              return (
                <LazyLoadImage className="gallery-img" src={item.image.url} alt="" style={{ maxWidth: "99%" }} effect="blur"/>
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </Layout>
    </div>
  );
}

export const query = `
  query MyQuery {
    allGalleries {
      image {
        url
      }
    }
  }
`;

export default Gallerij;
